
import Row from "src/kit/Row";
import { StatusContainer, TableActions } from "../styles"
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Icon from "src/assets/icons/KeyIcon";
import DownloadIcon from "src/assets/icons/DownloadIcon";
import { useTranslation } from 'react-i18next';

const EndpointTableConfig = ( ShowNodeModal,setSelected ,setPageInfoHandler ,downloadDocumentHandler,direction ) => {
  const { t } = useTranslation();
  return {
    showHeader: true,

    row: [
      {
        headerTitle: t('rowIndex'),
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: t('name'),
        displayTitle: "name",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            props.row.id === "" ?
              <Row>
                { props.row.name}
              </Row> :
              <Link onClick={() => {setPageInfoHandler({displayTitle :t('details') ,path: '/endpoint/detail'})}} to={{ pathname: '/endpoint/detail', state: { id: props.row.id, name: props.row.name, description: props.row.description } }}>
                <Row>
                  {props.row.name !== "" ? props.row.name : "-"}
                </Row>
              </Link>
          );
        },
      },
      {
        headerTitle: t("matchingUrl"),
        displayTitle: "matchingUrl",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.matchingUrl !== "" ? props.row.matchingUrl : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t("endpointType"),
        displayTitle: "endpointType",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.endpointType !== "" ? props.row.endpointType : "-"}
            </Row>
          );
        },
      },

      {
        headerTitle: t("status"),
        displayTitle: "statusPercentage",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <StatusContainer width={props.row.statusPercentage} direction={direction}>
              <div id="inner">
                <Box id="number">

                {props.row.statusPercentage !== "" ? props.row.statusPercentage.toFixed(2) * 100 + "%" : "-"}
                </Box>
              </div>
            </StatusContainer>
          );
        },
      },

      {
        headerTitle: t("actions"),
        displayTitle: "id",
        size: 2,
        responsiveShow: false,
        element: (props) => {
          return (

            <TableActions>
              <Grid container>
                <Grid item p={1} display="flex" className="">
                  <Box type="detail" className="tooltip table_btn"
                    onClick={() => {
                      setSelected(props.row);
                      ShowNodeModal(props.row);
                    }} >
                    <Box className="tooltiptext"> {t("apiKey")} </Box>
                    <Icon /></Box>

                </Grid>
                {/* <Grid item p={1} display="flex" >
                  <Box type="detail" className="table_btn  tooltip"
                    onClick={() => {
                      downloadDocumentHandler(props.row);
                    }}
                    >
                    <Box className="tooltiptext"> {t("download")}</Box>
                    <DownloadIcon /></Box>
                </Grid> */}
              </Grid>
            </TableActions>
          );
        },
      },
    ],
  };
};
export default EndpointTableConfig;