import styled, { css } from "styled-components";
import Row from "src/kit/Row";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
  padding : 10px 20px;
  border-top :1px solid #ccc;
  border-bottom :1px solid #ccc;

`;

export const ExitContainer = styled(Row)`
  padding :20px;

`;
