
import Row from "src/kit/Row";
import { useTranslation } from 'react-i18next';

const HyperLedgerFabricTableConfig = () => {
  const { t } = useTranslation();
  return {
    showHeader: true,

    row: [
      {
        headerTitle: t('rowIndex'),
        displayTitle: "rowIndex",
        size: 1,
        responsiveShow: true,
        element: (props) => {
          return <Row>{props.index + 1}</Row>;
        },
      },
      {
        headerTitle: t('name'),
        displayTitle: "name",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.name !== "" ? props.row.name : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t("status"),
        displayTitle: "status",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
              {props.row.status !== "" ? props.row.status : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t("organizationsCount"),
        displayTitle: "organizationsCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
             {props.row.organizationsCount !== "" ? props.row.organizationsCount : "-"}
            </Row>
          );
        },
      },
      {
        headerTitle: t("channelsCount"),
        displayTitle: "channelsCount",
        size: 2,
        responsiveShow: true,
        element: (props) => {
          return (
            <Row>
                {props.row.channelsCount !== "" ? props.row.channelsCount : "-"}
            </Row>
          );
        },
      },
    ],
  };
};
export default HyperLedgerFabricTableConfig;