import styled, { css } from "styled-components";
import Column from "src/kit/Column";

;
export const FormContainer = styled(Column)`
padding : 10px 20px;
display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

`;

