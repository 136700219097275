import React, { useEffect } from "react";
import { RegisterCompleteFormWrapper } from "./styles";
import { Box, Grid } from "@mui/material"
import { useLocation, Link } from "react-router-dom";
import LoginActions from "src/redux/Login/Actions";
import { useDispatch, useSelector } from "react-redux";
import Button from "src/kit/Button";
import Pic from "src/assets/images/loginRegister.png";
import ConfirmationIcon from "src/assets/icons/CheckIcon";
import CloseIcon from "src/assets/icons/AlertIcon"
import { LoginFormContainer, LogoContainer } from "../LoginRegister/LoginForm/styles";
import LogoIcon from "src/assets/icons/LogoIcon"


function extractCode(string) {
    const keyword = "code=";
    const startIndex = string.indexOf(keyword);
    if (startIndex === -1) { return null; }
    return string.substring(startIndex + keyword.length);
}

const RegisterComplete = () => {
    let location = useLocation();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('userId');
    let activationCode = extractCode(location.search);

    const LoginState = useSelector((state) => state.Login);
    const { activateLoading, activateStatus } = LoginState;

    useEffect(() => {
        dispatch(LoginActions.confirmEmail({ userId: email, code: encodeURIComponent(activationCode) }))
    }, [])
    return (
        <RegisterCompleteFormWrapper src={Pic}>
            <LoginFormContainer>
                <Grid container id="login-container">
                    <Grid item xs={12} sm={5}>
                        <LogoContainer>
                            <LogoIcon />
                            <a href="https://polaristech.ir/">polaristech.ir</a>
                        </LogoContainer>
                    </Grid>
                    <Grid item xs={12} sm={7} display="flex" justifyContent="center" alignItems="center">
                        {activateLoading !== true ?
                            activateStatus === true ?
                                <Grid container px={4} display="flex" justifyContent="center">
                                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                                        <ConfirmationIcon />
                                    </Grid>
                                    <Grid item xs={12} py={3} display="flex" justifyContent="center" fontSize={18}>
                                        <Box>
                                            حساب شما فعال شده است
                                        </Box>
                                    </Grid>
                                    <Grid item xs={10} >
                                        <Box>
                                            <Link to="/">
                                                <Button
                                                    onClick={() => formik.handleSubmit()}
                                                    text="ورود به پنل کاربری"
                                                >
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>


                                </Grid> :
                                <Grid container px={4} display="flex" justifyContent="center">
                                    <Grid item xs={12} p={1} display="flex" justifyContent="center">
                                        <CloseIcon />
                                    </Grid>
                                    <Grid item xs={12}  display="flex" justifyContent="center" fontSize={18}>
                                        حساب شما فعال نشده است
                                    </Grid>
                                    <Grid fontSize={14} py={3}>
                                    لطفا جهت رفع مشکل با پشتیبانی تماس حاصل نمایید
                                    </Grid>
                                    <Grid item xs={10} >
                                        <Box>
                                            <Link to="/">
                                                <Button
                                                    onClick={() => formik.handleSubmit()}
                                                    text="صفحه اصلی"
                                                >
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            : <div className="loader"></div>
                        }
                    </Grid>
                </Grid>
            </LoginFormContainer>

        </RegisterCompleteFormWrapper>
    )
}
export default RegisterComplete;