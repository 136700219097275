
import React from "react";
import { Box, Button, Grid, } from "@mui/material";
import DeleteIcon from "src/assets/icons/DeleteIcon";
import filterCrtl from "../../hyperledger.controllers";
import { FormInput } from "src/kit/Form";
import { FormContainer } from "../../style";
import VirtualizedSelect from "react-virtualized-select";
import { useTranslation } from 'react-i18next';

const ChannelSetting = ({
    organizationsListOptions,
    organizationsPeersOptions
}) => {
    const { handleAddChannel,
        handleChangeChannel,
        handleDeleteChannel,
        handleSubmitChannel,
        findRelatedPeersToOrganization,
        channels } = filterCrtl();
        const { t } = useTranslation();

    return (
        <FormContainer>
            <Grid container py={1} m={2}>
                <Grid xs={2} item display="flex" p={1} className="add-container clickable" onClick={handleAddChannel} >
                    <Box display="flex" alignItems="center" className={"filter-title"}>
                       {t("addChannel")}
                    </Box>
                </Grid>
                <Grid xs={12} item >
                    <Grid container >
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("ChannelName")}</Box>
                        </Grid>
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("Organizations_involved_in_the_organization")}</Box>
                        </Grid>
                        <Grid xs={3.5} item p={1}>
                            <Box className="form_lable">{t("Peers_of_the_organization_in_the_channel")}</Box>
                        </Grid>
                        <Grid xs={1} item p={1}>

                        </Grid>
                    </Grid>
                    {channels.map((form, index) => (
                        <Grid container key={index}>
                            <Grid xs={3.5} item p={1}>
                                <FormInput
                                    value={form.name}
                                    name="name"
                                    id="name"
                                    placeholder={t("ChannelName")}
                                    onChange={(e) => { handleChangeChannel(index, 'name', e.target.value) }}
                                />
                            </Grid>
                            <Grid xs={3.5} item p={1}>
                                <VirtualizedSelect
                                    value={form.organization}
                                    noResultsText={t("No data to display")}
                                    name="organization"
                                    id="organization"
                                    placeholder={t("select")}
                                    onChange={(selected) => {
                                        findRelatedPeersToOrganization(selected.value ,index)
                                        handleChangeChannel(index, 'organization', selected.value) ;
                                        handleChangeChannel(index, 'organizationName', selected.label) 
                                    }}
                                    maxHeight={150}
                                    options={organizationsListOptions}
                                    multi={false}
                                    backspaceToRemoveMessage=""
                                />
                            </Grid>
                            <Grid xs={3.5} item p={1}>
                                <VirtualizedSelect
                                    value={form.peers}
                                    noResultsText={t("No data to display")}
                                    name="peers"
                                    id="peers"
                                    placeholder={t("select")}
                                    onChange={(selected) => {
                                        let value = selected.map(each => each.value).join();
                                        let label = selected.map(each => each.label).join();
                                        handleChangeChannel(index, 'peers', value) ;
                                        handleChangeChannel(index, 'peersName', label) 

                                    }}
                                    maxHeight={150}
                                    options={organizationsPeersOptions[index]}
                                    multi={true}
                                    backspaceToRemoveMessage=""
                                    disabled={form.organization === "" ? true : false}
                                />
                            </Grid>
                            <Grid xs={1} item p={1} display="flex" justifyContent="center" alignItems="center">
                                <span className="clickable" onClick={() => handleDeleteChannel(index)}><DeleteIcon /></span>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container display="flex" justifyContent="end">
                        <Grid item xs={4} p={2} md={4} lg={2}>
                            <button
                                color="primary"
                                size="lg"
                                onClick={handleSubmitChannel}
                                className="ml-1 btn-form"
                            >
                                <Box className="color-white" p={2}>{t("finish")}</Box>
                            </button>
                        </Grid>
                    </Grid>
                </Grid >
            </Grid >
        </FormContainer>

    )
}
export default ChannelSetting;