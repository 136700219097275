import React, { useEffect, useState } from "react";
import { useDispatch ,useSelector } from "react-redux";
import HyperledgerFabricActions from "src/redux/HyperledgerFabric/Actions";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';


export default () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const direction = useSelector((state) => state.App.direction);

    const [organizations, setOrganizations] = useState([{
        orgName: "", MSPId: "", peerNum: ""
    }]);
    const [errors, setErrors] = useState([]); // Track errors
    const [channels, setChannels] = useState([{
        name: "", organization: "",organizationName: "", peers: "",peersName :""
    }]);
    const HyperledgerFabricState = useSelector((state) => state.HyperledgerFabric);
    const {organizationsPeersList ,orderrerInfo ,networkName} = HyperledgerFabricState;
    ////////////////////////////////////////////organization List
    const handleAddForm = () => {
        setOrganizations(organizations.concat([{ orgName: '', MSPId: '', peerNum: '' }]));
    };
    const handleOrgChange = (index, key, value) => {
        const updatedOrgs = [...organizations];
        updatedOrgs[index][key] = value;
        setOrganizations(updatedOrgs);

        // Reset error for the changed field
        const updatedErrors = [...errors];
        if (updatedErrors[index]) {
            updatedErrors[index][key] = ""; // Clear the error for that field
            setErrors(updatedErrors);
        }
    };
    const handleDeleteForm = (index) => {
        setOrganizations(prev => {
            if (prev.length === 1) {
                toast.error("You cannot delete the last organization form.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return prev;
            }
            const newForms = [...prev];
            newForms.splice(index, 1);
            return newForms;
        });
    };

    const validateForms = () => {
        const newErrors = organizations.map((form) => {
            let formErrors = {};
            if (!form.orgName) formErrors.orgName = t("requiredMessage");
            if (!form.MSPId) formErrors.MSPId = t("requiredMessage");
            if (!form.peerNum || isNaN(form.peerNum)) formErrors.peerNum = t("This_field_is_required_and_must_be_a_number");
            return formErrors;
        });

        setErrors(newErrors);
        // Return whether the forms are valid
        return newErrors.every((formErrors) => Object.keys(formErrors).length === 0);
    };
    const handleSubmitOrganization = async (e) => {
        // e.preventDefault();

        if (!validateForms()) {
            toast.error(t("please_fix_validation_errors"), {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }
        dispatch(HyperledgerFabricActions.setOrganizationList(organizations));
        toast.success(t("DoneSuccessfully"), {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
    };
    ////////////////////////////////////////////////////////////

    const handleSubmit = (values) => {

        const orderer ={
            "name": values.ordererName,
            "port": values.ordererPort,
            "type": values.ordererType,
        }
        handleSubmitOrganization();
        dispatch(HyperledgerFabricActions.setOrdererInfo(orderer))
        dispatch(HyperledgerFabricActions.setNextHyperLegerStep(2));
    }

    /////////////////////////////////////////////////////peers
    const handleSubmitOrganizationsPeers = (values) => {
        dispatch(HyperledgerFabricActions.setOrganizationsPeersList(values));
        dispatch(HyperledgerFabricActions.setNextHyperLegerStep(3));
    }

    const handlePeerChange = (e, formik, callBack, entry, entryIndex, peerIndex) => {
        let value = e.target.value;
        let name = e.target.name;
        formik.setFieldValue(`entries[${entryIndex}].peers[${peerIndex}][${name}]`, e.target.value);
        callBack && callBack(e, name, value);
    };
    ////////////////////////////////////////////////////////channels

    const handleAddChannel = () => {
        setChannels(channels.concat([{  name: "", organization: "",organizationName: "", peers: "",peersName :"" }]));
    };
    const handleChangeChannel = (index, key, value) => {
        const newForms = [...channels];
        newForms[index][key] = value;
        setChannels(newForms);
    };
    const handleDeleteChannel = (index) => {
        const newForms = [...channels];
        newForms.splice(index, 1);
        setChannels(newForms);
    };
    const findRelatedPeersToOrganization = (value ,index) => {
        dispatch(HyperledgerFabricActions.setOrganizationPeersOption({value ,index}));
    }
    const handleSubmitChannel = async (e) => {
        let newChannel = [];
        channels.map((each) => {
            let separatedArray = each.peersName.split(',');
            let item = {
                name: each.name,
                organization: each.organization,
                organizationName: each.organizationName,
                peers: each.peers,
                peersName: separatedArray,
            }
            newChannel.push(item);
        })
        let channelsList = [];
        newChannel.map((each) => {
            let organizationPeerName = [];
            each.peersName.map((per) => {
                let h = {
                    organizationName: each.organizationName,
                    peerName: per
                }
                organizationPeerName.push(h);
            })
            let item = { name: each.name, organizationPeerName: organizationPeerName }
            channelsList.push(item);
        })
        let body = {
            "name": networkName,
            "organizations": organizationsPeersList,
            "channels": channelsList,
            "orderer": orderrerInfo
        };
        dispatch(HyperledgerFabricActions.addHyperLedger(body));
    };



    return {
        handleAddForm,
        handleOrgChange,
        handleDeleteForm,
        handleSubmitOrganization,
        validateForms,
        organizations,
        errors,
        setErrors,
        handleSubmit,
        handleSubmitOrganizationsPeers,
        handlePeerChange,

        handleAddChannel,
        handleChangeChannel,
        handleDeleteChannel,
        handleSubmitChannel,
        channels,
        findRelatedPeersToOrganization,
        direction
    }
}
