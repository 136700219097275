import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="24"
      fill="none"
      viewBox="0 0 41 24"
    >
      <path
        fill="#898989"
        fillRule="evenodd"
        d="M20.742.645C11.702.645 3.982 5.4.854 12.113 3.982 18.825 11.702 23.58 20.742 23.58c9.04 0 16.76-4.755 19.888-11.467C37.503 5.4 29.782.645 20.742.645zm0 19.113c-4.99 0-9.04-3.425-9.04-7.645s4.05-7.645 9.04-7.645c4.99 0 9.04 3.425 9.04 7.645s-4.05 7.645-9.04 7.645zm0-12.232c-3.001 0-5.424 2.048-5.424 4.587 0 2.538 2.423 4.587 5.424 4.587 3.002 0 5.424-2.05 5.424-4.587 0-2.539-2.422-4.587-5.424-4.587z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
